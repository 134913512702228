<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Карточка правового источника</p>
    </div>
    <div>
      <div class="form-group required">
        <ComponentInput
          v-model="card.name"
          label="Название"
          required
          :maxLength="1000"
          placeholder="Введите название"
        />
      </div>
      <div class="form-group">
        <label class="form-label">Файл</label>
        <FileUpload width="100%" :entity="card" @deleteFile="removeFile" prop-name="file" @uploaded="fileUploaded" />
      </div>
      <div class="form-group required">
        <ComponentInput
          label="Тип НПА"
          :select2Settings="select2Settings"
          :select2Options="legalActTypes"
          v-model="card.legalActTypeId"
          required
          type="select2"
        />
      </div>
      <div class="form-group">
        <ComponentInput
          v-model="card.legalPortalLink"
          label="Ссылка на правовой портал"
          :maxLength="1000"
          placeholder="Введите ссылку на правовой портал"
        />
      </div>
      <div class="form-group mt-10">
        <div class="checkboxInput">
          <input type="checkbox" v-model="card.isActive" id="chActive" />
          <label for="chActive">Запись активна</label>
        </div>
      </div>
    </div>
    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock btn-type="secondary" title="Отмена" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import Constants from '@/common/constants';
  import Utils from '@/common/utils';

  import ComponentInput from '../../../common/components/ComponentInput';
  import FileUpload from '../../../common/components/FileUpload';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import API from '../../navigator/api/legalsource';
  import NsiApi from '../../nsi/api/nsi';

  export default {
    name: 'LegalSourceCard',
    components: { FileUpload, ButtonStock, ComponentInput },
    data() {
      return {
        id: this.$route.params.id,
        card: {},
        legalActTypes: [],
        select2Settings: Constants.select2Settings,
      };
    },
    created() {
      this.loadDictionaries();
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.card = response.data;
          });
        }
      },
      loadDictionaries() {
        NsiApi.search('nsilegalacttype', { pageNumber: 1, pageSize: 0, isActive: true })
          .then((response) => {
            const list = response.data.items.map((x) => ({
              name: x.name,
              id: x.id,
              parentId: x.parentId,
              parentName: x.parent?.name,
              isLeaf: x.id === 1,
            }));
            this.legalActTypes = Utils.groupList(list, (x) => ({ id: x.parentId ?? 0, text: x.parentName ?? '' }));
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        API.update(this.card)
          .then(() => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (this.card.id) {
              this.$router.push({ name: 'LegalSourceCard', params: { id: this.card.id, action: 'edit' } });
            } else {
              this.$router.push({ name: 'LegalSources' });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      fileUploaded(e) {
        this.card.file = e.data;
        this.card.fileId = e.data ? e.data.id : null;
      },
      removeFile() {
        this.card.file = {};
        this.card.fileId = null;
      },
      close() {
        this.$router.push({ name: 'LegalSources' });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        card: {},
      };
    },
  };
</script>

<style lang="scss" scoped>
  .form-label {
    font-weight: 400 !important;
    font-size: 14px;
    color: #231f20;
    line-height: 160%;
  }
</style>
