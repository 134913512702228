import axios from 'axios';

export default {
  search: function (request) {
    return axios.get('/api/legalsource/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/legalsource/find/${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/legalsource/${data.id}`, data);
    else return axios.post(`/api/legalsource`, data);
  },
  delete: function (id) {
    return axios.delete(`/api/legalsource/${id}`);
  },
};
